import { Accessor, createSignal, onCleanup, onMount } from "solid-js";
import { PLUTO_ANIMATION_CONSTANTS, PLUTO_ONBOARDING_DATA } from "./onboarding";
import AnimatingNumbers from "./animating_numbers";

interface NumberAnimation {
  displayValue: Accessor<string>;
  startAnimation: () => void;
}

function useTypewriterEffect(): NumberAnimation {
  const [displayValue, setDisplayValue] = createSignal(
    PLUTO_ONBOARDING_DATA.numbers[0]
  );
  let index = 0;
  let typingInterval: NodeJS.Timeout;

  const startAnimation = () => {
    let currentNumber = PLUTO_ONBOARDING_DATA.numbers[index];
    let charIndex = 0;
    let isDeleting = false;

    typingInterval = setInterval(() => {
      if (!isDeleting) {
        charIndex++;
        if (charIndex <= currentNumber.length) {
          setDisplayValue(currentNumber.slice(0, charIndex));
        } else {
          setTimeout(() => {
            isDeleting = true;
          }, PLUTO_ANIMATION_CONSTANTS.DELETE_DELAY);
        }
      } else {
        charIndex--;
        if (charIndex >= 0) {
          setDisplayValue(currentNumber.slice(0, charIndex));
        } else {
          index = (index + 1) % PLUTO_ONBOARDING_DATA.numbers.length;
          currentNumber = PLUTO_ONBOARDING_DATA.numbers[index];
          isDeleting = false;
        }
      }
    }, PLUTO_ANIMATION_CONSTANTS.TYPING_SPEED);
  };

  onCleanup(() => clearInterval(typingInterval));
  return { displayValue, startAnimation };
}

interface GiftAmountViewProps {
  step: number;
  height: number;
}

export function GiftAmount(props: GiftAmountViewProps) {
  const { displayValue, startAnimation } = useTypewriterEffect();
  startAnimation();

  function getPosition() {
    if (props.step < 2) {
      return `translateY(${props.height / 2 - 100}px) scale(0)`;
    } else if (props.step === 2) {
      return `translateY(${props.height / 2 - 100}px)`;
    } else {
      return `translateY(${0}px)`;
    }
  }

  return (
    <>
      <div class="mt-3"></div>
      <div
        class={` flex  transition-all  duration-500 ease-out `}
        style={{
          transform: getPosition(),
        }}
      >
        <AmountDisplay
          amount={props.step < 3 ? displayValue : () => "₹2,000"}
          showCursor={props.step < 3 ? true : false}
        />
      </div>
      <div
        class="flex h-min w-full flex-1  transition-opacity duration-300"
        classList={{
          "opacity-0": props.step < 3,
        }}
      >
        <AnimatingNumbers />
      </div>
    </>
  );
}

interface AmountDisplayProps {
  amount: Accessor<string>;
  showCursor?: boolean;
  class?: string;
}

function AmountDisplay(props: AmountDisplayProps) {
  return (
    <div class="rounded-[20px] bg-[linear-gradient(to_right_top,_#ff69b4,_#ffff00)] p-px">
      <div
        class={`text-xl flex items-center justify-center rounded-[20px] bg-black pl-[24px] pr-[20px] text-[44px] font-bold text-white ${props.class}`}
      >
        <span class="text-[44px]">{props.amount?.()}</span>
        {props.showCursor && (
          <span class="animateBlink ml-1 text-[44px] font-extralight">|</span>
        )}
      </div>
    </div>
  );
}
