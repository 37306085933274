import { createSignal, onMount, Show } from "solid-js";
import {
  onboarding_sample_gift,
  plutoBrand1,
  plutoBrand2,
  plutoBrand3,
} from "~/assets/assets";
import { GiftAmount } from "./amount_classification";

export function GiftCardView(props: { step: number }) {
  const [gcHeight, setGcHeight] = createSignal<number | null>(null);
  const [brandIconsHeight, setBrandIconsHeight] = createSignal<number | null>(
    null
  );
  const [scale, setScale] = createSignal(1);
  const [newGcHeight, setNewGcHeight] = createSignal(0);
  const [isPlutoGiftImageLoaded, setIsPlutoGiftImageLoaded] =
    createSignal(false);
  let img!: HTMLImageElement;
  onMount(() => {
    img.onload = () => {
      if (!isPlutoGiftImageLoaded()) {
        setIsPlutoGiftImageLoaded(true);
        calculateScale();
      }
    };

    if (
      img.complete &&
      img.naturalWidth >= 0 &&
      img.naturalHeight >= 0 &&
      !isPlutoGiftImageLoaded()
    ) {
      setIsPlutoGiftImageLoaded(true);
      calculateScale();
    }
  });

  function calculateScale() {
    if (!gcHeight()) {
      setGcHeight(img.offsetHeight);
    }

    const brandIcons = document.getElementById("onboarding-brand-icons");
    if (brandIcons && !brandIconsHeight()) {
      setBrandIconsHeight(brandIcons.offsetHeight);
    }

    if (!gcHeight() || !brandIconsHeight()) return;

    setNewGcHeight(gcHeight()! - brandIconsHeight()!);
    let scale = newGcHeight() / gcHeight()!;
    setScale(scale * 100);
  }

  return (
    <div class="flex h-full w-full flex-1 flex-col items-center justify-center px-5 ">
      <div class="relative flex w-full max-w-[343px] flex-col items-center justify-start ">
        <img
          ref={img}
          src={onboarding_sample_gift}
          alt="Gift Card"
          class={`flex w-full origin-top justify-start  transition-all duration-[300ms]  ease-out`}
          classList={{
            "blur-[2px] ": props.step >= 2,
            "opacity-0": props.step === 3,
          }}
          style={
            props.step > 0
              ? {
                  transform: `scale(${scale()}%)`,
                }
              : {}
          }
        />
        {buildBrandIcons()}
        <Show when={gcHeight()}>
          <div
            style={
              props.step > 0
                ? {
                    height: gcHeight() + "px",
                  }
                : {}
            }
            id="onboarding-gift-amount-parent"
            class="absolute left-[1/2] top-0 z-[3] flex h-full w-full max-w-[288px] origin-top translate-x-[1/2] flex-col items-center justify-start   transition-all      duration-300 ease-out lg:max-w-full  "
          >
            <GiftAmount step={props.step} height={gcHeight()!} />
          </div>
        </Show>
      </div>
    </div>
  );

  function buildBrandIcons() {
    return (
      <div
        id="onboarding-brand-icons"
        class="absolute bottom-0 flex w-full  flex-col"
      >
        <div class="mt-8"> </div>
        <div
          class=" flex h-[80px] w-full origin-bottom flex-row items-center justify-between  transition-all duration-300  ease-out"
          classList={{
            "opacity-0 scale-0 ": props.step === 0,
          }}
        >
          <img src={plutoBrand1} class="w-[30%] max-w-fit" />
          <img src={plutoBrand2} class="w-[30%] max-w-fit translate-x-[5px]" />
          <img src={plutoBrand3} class="w-[30%] max-w-fit" />
        </div>
      </div>
    );
  }
}
