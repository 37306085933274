import { Accessor, createSignal, onCleanup } from "solid-js";
import { classification_arrows_v2 } from "~/assets/assets";
import { PLUTO_ANIMATION_CONSTANTS, PLUTO_ONBOARDING_DATA } from "./onboarding";

export default function AnimatingNumbers() {
  const { numberSets, numberOpacity, startNumberCycle, getNextNumberSet } =
    useNumberCycle();
  startNumberCycle();

  return (
    <>
      <div class="flex h-max w-full flex-col items-center justify-end   ">
        <div class="flex h-3"></div>
        <div class="h-full w-full flex-1 ">
          <img
            src={classification_arrows_v2}
            class="h-full max-h-[108px] w-full  object-fill px-8 "
          />
        </div>
        <div class=" flex h-3"></div>

        <div class=" flex w-full animate-fade flex-row items-center justify-between  ">
          {buildIndividualNumber({
            index: 0,
          })}

          {buildIndividualNumber({
            index: 1,
          })}

          {buildIndividualNumber({
            index: 2,
          })}
        </div>
      </div>
    </>
  );

  function buildIndividualNumber(props: { class?: string; index: number }) {
    return (
      <div
        class="  rounded-lg bg-[linear-gradient(to_right_top,_#473D42,_#FFE790)] p-px"
        classList={{
          [props.class ?? ""]: !!props.class,
        }}
      >
        <div class="flex h-[28px] w-[80px] flex-col items-center justify-center overflow-y-hidden rounded-lg bg-black   px-3 py-2.5 text-bold text-white lg:h-[40px] lg:w-[100px]">
          <div
            class="flex w-full flex-col items-center justify-center text-[15px]  lg:text-[21px] "
            classList={{
              [`translate-y-[-17px] transition-all duration-[${PLUTO_ANIMATION_CONSTANTS.COMBO_FADE_DELAY}ms] ease-[cubic-bezier(.06,1.25,.59,1.1)]`]:
                numberOpacity()[props.index],
              [`translate-y-[16px]`]: !numberOpacity()[props.index],
            }}
          >
            <p>{numberSets()[props.index]}</p>
            <div class="mt-3"></div>
            <p>{getNextNumberSet()[props.index]}</p>
          </div>
        </div>
      </div>
    );
  }

  function useNumberCycle(): NumberCycle {
    const [numberSets, setNumberSets] = createSignal(
      PLUTO_ONBOARDING_DATA.numberCombinations[0]
    );
    const [numberOpacity, setNumberOpacity] = createSignal([
      false,
      false,
      false,
    ]);
    let [comboIndex, setComboIndex] = createSignal(0);
    let comboInterval: NodeJS.Timeout;

    function getNextNumberSet() {
      let c =
        (comboIndex() + 1) % PLUTO_ONBOARDING_DATA.numberCombinations.length;
      let nextNumberSet = PLUTO_ONBOARDING_DATA.numberCombinations[c];
      return nextNumberSet;
    }

    const startNumberCycle = () => {
      comboInterval = setInterval(() => {
        setNumberOpacity([true, true, true]);

        setTimeout(() => {
          setComboIndex(
            (comboIndex() + 1) % PLUTO_ONBOARDING_DATA.numberCombinations.length
          );
          setNumberSets(PLUTO_ONBOARDING_DATA.numberCombinations[comboIndex()]);
          setNumberOpacity([false, false, false]);
        }, PLUTO_ANIMATION_CONSTANTS.COMBO_FADE_DELAY);
      }, PLUTO_ANIMATION_CONSTANTS.COMBO_CYCLE_INTERVAL);
    };

    onCleanup(() => clearInterval(comboInterval));
    return {
      numberSets,
      numberOpacity,
      startNumberCycle,
      getNextNumberSet,
    };
  }
}

interface NumberCycle {
  numberSets: Accessor<string[]>;
  numberOpacity: Accessor<boolean[]>;
  startNumberCycle: () => void;
  getNextNumberSet: () => string[];
}
