"use server";

import { redirect } from "@solidjs/router";
import { deleteCookie, getCookie } from "vinxi/http";
import { Cookie } from "~/types";
import { APIError } from "~/utils/fetch";
import { getPlutoCredits, getUserProfile } from "../apis/server_apis";
import { Credits } from "../types/pluto";
import { UserProfile } from "../types/user";

export type PlutoLoginRouteData = {
  isLoggedIn: boolean;
  isProfileComplete: boolean | undefined;
};

export default async function getPlutoSenderLoginRouteData(): Promise<PlutoLoginRouteData> {
  let sid = getCookie(Cookie.SessionId);

  if (!sid) {
    return {
      isLoggedIn: false,
      isProfileComplete: undefined,
    };
  }

  let credits: Credits | undefined;
  let profile: UserProfile | undefined;

  let credits$P = getPlutoCredits({
    throwAuthError: true,
  });
  let profile$P = getUserProfile(
    {},
    {
      throwAuthError: true,
    }
  );

  try {
    let resolved = await Promise.all([credits$P, profile$P]);
    credits = resolved[0];
    profile = resolved[1];
    if (profile.firstName?.trim()) {
      // if profile is complete, redirect to pluto home
      throw redirect("/pluto/create/new");
    } else {
      return {
        isLoggedIn: true,
        isProfileComplete: false,
      };
    }
  } catch (error) {
    if (error instanceof APIError && error.code === 401) {
      deleteCookie(Cookie.SessionId);
      return {
        isLoggedIn: false,
        isProfileComplete: undefined,
      };
    } else {
      throw error;
    }
  }
}
