import { useIsRouting, useNavigate } from "@solidjs/router";
import { PhosphorIcon } from "~/widgets/icons";
import { ButtonDark } from "~/components/gifting/verification/components";

export function OnboardingModal() {
  const navigate = useNavigate();
  const isRouting = useIsRouting();

  return (
    <div class="relative h-full w-full lg:h-[427px]">
      <div class="mt-4 flex flex-col gap-2 text-white lg:mt-0">
        <p class="font-jakartaSans text-smallBold uppercase tracking-[1.1px]">
          Boring but important stuff
        </p>
        <h1 class="mb-6 font-jakartaSans text-[28px] font-semibold">
          Before you start talking to Pluto, please note:
        </h1>
      </div>
      <div class="flex flex-col gap-6 border-t border-basePrimaryMedium pt-6 text-white">
        <div class="flex gap-2">
          <PhosphorIcon
            name="star-four"
            fontSize={20}
            size="fill"
            class={`h-5 w-5 text-baseTertiaryLight`}
          />
          <div class="flex flex-col items-baseline gap-1">
            <h3 class="text-bold">Pluto is only for gifting</h3>
            <p class="text-medium text-baseSecondaryLight">
              If Pluto detects you're off-topic, the conversation will end
              without a gift.
            </p>
          </div>
        </div>
        <div class="flex gap-2">
          <PhosphorIcon
            name="star-four"
            fontSize={20}
            size="fill"
            class={`h-5 w-5 text-baseTertiaryLight`}
          />
          <div class="flex flex-col items-baseline gap-1">
            <h3 class="text-bold">Pluto is still improving</h3>
            <p class="text-medium text-baseSecondaryLight">
              Everything is AI-driven and in beta—expect some surprises! Your
              feedback helps us get better.
            </p>
          </div>
        </div>
      </div>
      <div class="absolute bottom-5 w-full px-1 lg:bottom-0">
        <ButtonDark
          class="w-full !rounded-full"
          isLoading={isRouting()}
          onClick={() => navigate("/pluto/create/new", { replace: true })}
        >
          <p class="text-buttonMedium font-bold text-textDark">
            Understood, let’s start
          </p>
        </ButtonDark>
      </div>
    </div>
  );
}
