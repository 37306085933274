import { cache, createAsync, useNavigate } from "@solidjs/router";
import { createEffect, createSignal, Match, Show, Switch } from "solid-js";
import PlutoLoginComponent from "~/components/pluto/login/login";
import PlutoOnboardingComponent from "~/components/pluto/login/onboarding";
import getPlutoSenderLoginRouteData from "~/server/data/pluto_sender_login_route_data";

type ViewState = "onboarding" | "login";
export type Step = 0 | 1 | 2 | 3;

const getPlutoSenderLoginRouteData$C = cache(
  getPlutoSenderLoginRouteData,
  "pluto_create_login_route_data"
);

export default function PlutoLoginRoute() {
  const [view, setView] = createSignal<ViewState>("onboarding");
  const [onboardingStep, setOnboardingStep] = createSignal<Step>(0);

  const routeData = createAsync(
    async () => {
      return await getPlutoSenderLoginRouteData$C();
    },
    { deferStream: true }
  );

  createEffect(() => {
    if (routeData()) {
      if (isLoggedInWithoutName()) {
        setView("login");
      }
    }
  });

  function isLoggedInWithoutName() {
    return routeData()?.isLoggedIn && !routeData()?.isProfileComplete;
  }

  return (
    <Show when={routeData()}>
      <div class="flex h-full  w-full overflow-y-scroll bg-black  bg-cover bg-no-repeat">
        <Switch>
          <Match when={view() === "onboarding"}>
            <PlutoOnboardingComponent
              currentStep={onboardingStep}
              onStepChange={(step) => setOnboardingStep(step)}
              onComplete={() => setView("login")}
            />
          </Match>
          <Match when={view() === "login"}>
            <PlutoLoginComponent
              step={isLoggedInWithoutName() ? "name" : "phone"}
              onBack={() => {
                setOnboardingStep(3);
                setView("onboarding");
              }}
            />
          </Match>
        </Switch>
      </div>
    </Show>
  );
}
